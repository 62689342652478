@import 'scss/variables/index.scss';

@media only screen and (min-width: $tablet) {
  .pd-contact {
    &-location {
      margin: $spacing-lg 0;

      &-wrapper,
      iframe {
        margin: $spacing-xl 0;
      }
    }

    &-form {
      padding: $spacing-xl $spacing-xs;
    }
  }
}

$pd-color-white: #ffffff;
$pd-color-light-gray: #f2f5f7;
$pd-color-gray: #cccccc;
$pd-color-dark-gray: #777777;
$pd-color-darker-gray: #444444;
$pd-color-black: #222222;

// color theme
$primary-color: #c8ab82;
$secondary-color: #fcebd2;
$light-cream-color: #f6f1eb;
$gray-color: #e5e5e5;
$cream-color: #f4f4f4;
$font-color: #59493b;
$font-secondary-color: #3b3938;
$form-border-color: #d9d6d4;

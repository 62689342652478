@font-face {
  font-family: 'Brandon';
  src: url('~fonts/Brandon_thin.otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('~fonts/Brandon_light.otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('~fonts/Brandon_reg.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('~fonts/Brandon_med.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('~fonts/Brandon_blk.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Garamond';
  src: url('~fonts/Garamond_light.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Garamond';
  src: url('~fonts/Garamond_light_it.otf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('~fonts/Montserrat_regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('~fonts/Montserrat_medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('~fonts/Montserrat_semibold.ttf');
  font-weight: 600;
  font-style: normal;
}

@import 'scss/utils/index';
@import 'scss/variables/index';

body {
  color: $font-color;
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 300x;
}

a {
  color: inherit;

  &:hover {
    color: $primary-color;
  }
}

.pd-cms {
  height: 100vh;
}

@import 'scss/variables/index.scss';

.pd-footer {
  background: $secondary-color;
  color: $font-color;
  font-family: $primary-font;
  padding: $spacing-lg;

  a {
    &:hover {
      color: $primary-color;
    }
  }

  .ant-row {
    margin-bottom: $spacing-lg;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .title {
    font-weight: bold;
  }

  .custom-design {
    font-size: $pd-font-size-md;
  }

  .list {
    display: block;
    padding: 0px;

    li {
      list-style: none;
    }
  }

  &-center {
    text-align: center;
  }
}
